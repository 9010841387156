<template>
    <div class="container">
        <h1 class="container_h">機構驗證</h1>
        <div class="container_yzm_er">
            <div style="width:50%;height:48%;float:left;text-align:center;">
               <span v-if="category">&nbsp;&nbsp;&nbsp;類別：</span>
               <span v-else>類別：</span>{{category}}
            </div>
            <div style="width:50%;height:48%;float:left;text-align:center;">
                <span>籌款項目標題：</span>{{title}}
            </div>
             <div style="width:50%;height:48%;float:left;text-align:center;">
                <span>機構：</span>{{agency}}
            </div>
            <!-- <div style="width:50%;height:48%;float:left;">
                <span>機構名字：</span>{{name}}
            </div> -->
        </div>
        <div class="container_yzm_yi">
            <div style="height: 5vh;">
                <el-input v-model="yzm" placeholder="請輸入驗證碼" autocomplete="off"></el-input>
            </div>
            <div slot="footer" style="text-align: center;margin-top: 35px;">
                <el-button type="danger" @click="dialogFormVisibles" round>進行驗證</el-button>
            </div>
        </div>
        <!-- <h1 style="text-align:center;color:red;font-size:20px;"><router-link :to="{ name: 'index' }">回到首頁</router-link></h1> -->
    </div>
</template>

<script>
// TODO: 还没有进行数据绑定。
import api from '@/api';
import qs from 'qs';
export default {
    data() {
        return {
           dialogFormVisible:true,
           yzm:'',
           category:'',
           agency:'',
           name:'',
           title:'',
        };
    },
    methods: {
        dialogFormVisibles(){
            if(!this.yzm){
                this.$message.error('請輸入驗證碼！');
            }else{
                let id = this.$route.params.id;
                let params = {
                    is_agency_confirm:1,
                    agency_verify:this.yzm
                };
                params= qs.stringify(params, { indices: false });
                api.verification.agenciesToVerifys(id,params).then(request => {
                    this.$message.success(request.msg);
                }).catch(error => {
                    this.$message.error(request.msg);
                });
                this.dialogFormVisible = false;
            }
        }
    },
    computed: {
    },
    watch:{
    },
    created:function (){
        let id = this.$route.params.id
        api.fundraise.getFundraiseDetails(id).then(response => {
            let request = response.data;
            // 種類
            this.category = request.category.name;
            // 項目標題
            this.title = request.project.title;
            // 機構
            this.agency = request.agency.name;
            // 個人name
            this.name = request.personal.name;
        }).catch(error=>{
            this.$message.error('數據異常，請重新獲取！');
        });
    }
};
</script>

<style lang="scss">
.container_h{
    text-align: center;
    margin-top: 8vh;
    font-size: 40px;
    font-weight: bold;
}
.container_yzm_yi{
    width: 25vw;
    margin: auto;
    height: 35vh;
    line-height: 25vh;
}
.container_yzm_er{
    width: 50vw;
    margin: auto;
    height: 15vh;
    line-height: 12vh;
    margin-top: 5vh;
    span{
        font-size: 20px;
        font-weight: bold;
    }
}
.el-dialog__close{
    display: table-column;
}
.container {
    min-height: calc(100vh - 48px);
    background-size: cover;
    background-position: center;
    padding: 96px 16px 16px;
    // @include fr-c-c;
}
.el-button span{
    color:#fff;
}
</style>